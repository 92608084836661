#root {
    width: 100%;
    height: 100vh;
}

.main-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden;
}

.main-content {
    height: 100%;
    width: 100%;
    max-width: 88vw;
}

.main-card {
    color: var(--cardHeader);
    background-color: rgba(255, 255, 255, 0.9);
    width: 33%;
    min-width: 50rem;
    height: fit-content;
    min-height: 20rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2.7rem;
    padding: 4rem;
}

.main-card .full {
    border-radius: 2.7rem;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-card .loader {
    width: 4rem;
    height: 4rem;
    border: 5px solid var(--textPrimary);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-device-width: 768px) {

    .main-card {
        width: 75%;
        min-width: 40rem;
    }
}

@media only screen and (max-device-width: 480px) {

    .main-card {
        width: 85%;
        min-width: 40rem;
    }
}


.main-card .card-title {
    color: var(--cardHeader);
    font-size: 5rem;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    font-weight: normal;
}

.main-card .card-subtitle {
    margin: 0;
    text-align: center;
    font-size: 2rem;
    font-weight: normal;
}

input {
    padding: 1rem;
    border: 1px solid #231F20;
    border-radius: 1rem;
    font-family: var(--fontMain);
}

input:focus-visible,
input:focus {
    outline-color: var(--textPrimary);
}

button {
    min-width: 10rem;
    min-height: 3rem;
    height: fit-content;
    width: fit-content;
    padding: 0.8rem 1.6rem;
    border-radius: 2rem;
    border: 1px solid var(--textPrimary);
    cursor: pointer;
    font-family: var(--fontMain);
    text-transform: uppercase;
    font-size: 1.4rem;
}

button:hover {
    opacity: 0.8;
}

button:focus-visible,
button:focus {
    outline-color: var(--textPrimary);
}

button.main-btn {
    background-color: var(--textPrimary);
    color: var(--mainColor);
}

button.second-btn {
    color: var(--textPrimary);
}

.main-form .alert {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--textAlert);
}
span.timeout-text{
    color: var(--textPrimary);
}
.pointer{
    cursor: pointer;
}