:root {
  --fontMain: -apple-system, 'Circe', sans-serif;
  --fontMenu: -apple-system, 'Circe', sans-serif;
  --mainColor: white;
  --textPrimary: rgb(239, 60, 68);
  --cardHeader: black;
  --textAlert: rgb(239, 60, 68);
}

@font-face {
  font-family: Circe;
  font-weight: 400;
  src: local("Circe-Regular"), url("./assets/fonts/circe/Circe-Regular.otf") format('opentype');
}

@font-face {
  font-family: Circe;
  font-weight: 800;
  src: local("Circe-Bold"), url("./assets/fonts/circe/Circe-Bold.otf") format('opentype');
}

html {
  font-size: 9pt;
  
}

@media only screen and (min-width: 200px) and (max-width: 767px)  {
  /*Put your CSS here for 200px to 767px width devices (cover all width between 200px to 767px */
  html {
    font-size: 4.5pt;
  }
 
}
@media only screen and (min-width: 768px) and (max-width: 1024px)  {
  /*Put your CSS here for 768px to 1024px width devices(covers all width between 768px to 1024px */   
  html {
    font-size: 7.5pt;
  }
}

@media only screen and (min-width: 200px) and (max-width: 767px) and (orientation : portrait) {
  /*Put your CSS here for 200px to 767px width devices (cover all mobile portrait width */  
  html {
    font-size: 5pt;
  }      
}



@media only screen and (max-device-width: 480px) {

  /* styles for mobile browsers smaller than 480px; (iPhone) */
  html {
    font-size: 4.5pt;
  }
}

@media only screen and (device-width: 768px) {

  /* default iPad screens */
  html {
    font-size: 6pt;
  }
}

body {
  margin: 0;
  font-family: var(--fontMain);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page {
  position: relative;
  width: 100%;
  height: 88%;
  background-color: white;
}

.main-page {
  /* background-image: url("./assets/backgrounds/01/0042.jpg"); */
  background-repeat: no-repeat;
  background-position: center center;
  margin: 3rem;
  width: calc(100% - 6rem);
  height: calc(100% - 6rem);
  position: relative;
}
@media only screen and (max-device-width: 768px) {

  .main-page{
    margin: 1rem;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
  }
}
@media only screen and (max-device-width: 480px) {

  .main-page{
    margin: 0px;
    width: 100%;
    height: 100%;
  }
}



@media only screen and (device-width: 768px) {

  .main-page{
    margin: 1rem;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
  }
}

@media only screen and (max-device-height: 480px) {

  .main-page{
    margin: 0px;
    width: 100%;
    height: 100%;
  }
}

.w-100{
  width: 100% !important;
}
.h-100{
  height: 100% !important;
}
.full{
  position:absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.underline{
  text-decoration: underline;
}