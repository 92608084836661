.header {
    width: 100%;
    height: 13%;
    max-height: 14rem;
    min-height: 7.5rem;
    border-bottom: 2px solid rgb(179, 179, 179);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
   
}

.header .main-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.header .main-content .mail{
    height: 60%;
    pointer-events: none;
    opacity: 0;
    aspect-ratio: 1;
}
.header .main-content .logo{
    aspect-ratio: 1;
    height: 60%;
}

.header .main-content .mail{
    max-height: 3rem;
}

.header .main-content .header-menu{
    height: 60%;
    width: 84%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.8rem;
    font-weight: 800;
    font-family: var(--fontMenu);
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1.5rem;
  
}

.header .main-content .header-menu a.menu-link{
    color: var(--textPrimary);
    text-decoration: none;
  
}